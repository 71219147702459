import { Injectable, OnInit, OnDestroy } from '@angular/core';
// import {GlobalmemService} from '../Services/globalmem.service';
import {BehaviorSubject} from 'rxjs';

export interface rights {
  allowDelete: boolean;
  allowAdd: boolean;
  allowEdit: boolean;
  allowView: boolean;
}

export enum access {
  MAINT_COMPANY   = 10,
  MAINT_USERS     = 13,
  MAINT_CUSTOMERS = 14,
  MAINT_VENDORS   = 15,
  CONFIG_ADMIN    = 16,
  MAINT_SHIPPING  = 17,
  MAINT_JOBS      = 18
}

export enum allow {
  SUPER = 'S',
  DELETE = 'H',
  ADD = 'A',
  EDIT = 'E',
  VIEW = 'V',
  OMIT = 'O',
  BAD = 'B'
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService implements OnDestroy {

  // Position 0 is company name length
  // Position 1 - 12 are company codes
  userRights = new BehaviorSubject<string>(''); // holds and supplies userrights string.


  constructor() { }


  // all rights are served from here and returned as rights object
  getRights(type: string) {
    let maintType = 0;

    switch(type) {
      case '00000':
        maintType = access.MAINT_USERS;
        break;
      case '00001':
        maintType = access.MAINT_CUSTOMERS;
        break;
      case '00002':
        maintType = access.MAINT_VENDORS;
        break;
      case '00003':
        maintType = access.MAINT_SHIPPING;
        break;
      case 'jobs':
        maintType = access.MAINT_JOBS;
        break;
      default: // if not in list dont allow
        let rts: rights = {allowDelete:false, allowAdd: false, allowEdit:false, allowView: false};
        return rts;
      // add more types here
      }
    let rts: rights = {
      allowDelete: this.authorized_pass(maintType, allow.DELETE),
      allowAdd : this.authorized_pass( maintType, allow.ADD),
      allowEdit : this.authorized_pass(maintType, allow.EDIT),
      allowView : this.authorized_pass( maintType, allow.VIEW)
    }
    return rts;
  }

  authorized(sec: number, typ: allow, exact = false) {
    return this.authorized_pass(sec, typ, this.userRights.getValue() , exact);
  }
  // sec = char position 13 and above
  authorized_pass(sec: number, typ: allow, rights2 = '', exact = false) {
    const rights = this.userRights.getValue().substr(21); // rights start at 21
    const ulen = rights.length;
    if (ulen <= sec) {
      return false;
    }
    const userVal = rights.substr(sec, 1);
    if (exact) {
      if (typ === userVal) {
        return true;
      } else {
        return false;
      }
    }

    const uv = this.allowToLevel( this.charToAllow(userVal));
    const tv = this.allowToLevel(typ);
    if (uv >= tv) {
      return true;
    } else {
      return false;
    }
  }

  // higher the level the more rights
  allowToLevel(theVal: allow): number {
    switch (theVal) {
      case allow.SUPER:
        return 6;
      case allow.DELETE:
        return 5;
      case allow.ADD:
        return 4;
      case allow.EDIT:
        return 3;
      case allow.VIEW:
        return 2;
      case allow.OMIT:
        return 1;
      default:
        return 0;
    }
  }

  testReg(pos: number, allow:string) {
   // ^.{8}[SCFE]
    const re = new RegExp('^.{' + pos.toString() + '}[' + allow + ']', "g");
    const rts = this.userRights.getValue()
    const rslt = re.test(rts);
    return rslt;
  }


  charToAllow(theChar: string): allow {
    switch (theChar.toUpperCase()) {
      case 'S':
        return allow.SUPER;
      case 'A':
        return allow.ADD;
      case 'H':
        return allow.DELETE;
      case 'E':
        return allow.EDIT;
      case 'V':
        return allow.VIEW;
      case 'O':
        return allow.OMIT;
      default:
        return allow.BAD;
    }
  }


  getCompany(): string {
    // first 11 is company second 10 is user id
    const ulen = this.userRights.getValue().length;
    if (ulen >= 11) { // string must be at least 11 long
      return this.userRights.getValue().substr(1, 10).trim();
    } else {
      return ''; // empty is error
    }
  }
  getUser(): string {
    // first 11 is company second 10 is user id
    const ulen = this.userRights.getValue().length;
    if (ulen >= 21) { // string must be at least 11 long
      return this.userRights.getValue().substr(11, 10).trim();
    } else {
      return ''; // empty is error
    }
  }

  getUserRights()  {
    const ulen = this.userRights.getValue().length;
    if (ulen > 21) { // string must be at least 21 long
      return this.userRights.getValue().substr(21).trim();
    } else {
      return ''; // empty is error
    }
  }

  ngOnDestroy() {
  }
}

