import {Name} from './name';
import { Subscription } from 'rxjs';

export class Namelist {
    lookup : string; // 00000 00001
    freindlyName: string; // Customers Employees Carriers
    data: Name[]; // the array of names
    sub: Subscription;
    filterStr: string;
    headings: object;

    constructor(lookup:string, freindlyName: string ) {
        this.lookup = lookup;
        this.freindlyName = freindlyName;
        this.data = [];
        this.filterStr = '';
    }

    public sort(){
        this.data.sort((a:Name, b:Name)=> {
          if(a.DisplayName < b.DisplayName) {
            return -1;
          } else {
            return 1;
          }
        });
    }

    filter (filt:string) {
      this.filterStr = filt;
      this.data.forEach((name) => {
      if (name.DisplayName.indexOf(filt) > -1){
          name.visible = true;
        } else {
          name.visible = false;
        }
      });
    }
  }
