import { Name } from '../../../flex/src/app/models/name';

export class Job  {
  JobID                    :string;
  FromID                   :string;
  SubJobs                  :string;
  SubAssets                :string;
  Compcode                 :string;
  Group1                   :string;
  Group2                   :string;
  Type                     :string;
  Subtype                  :string;   // Class
  CSS                      :string;
  IconURL                  :string;
  Heading                  :string;
  Hidden                   :boolean;
  CreateTime               :string;
  Enteredby                :string;
  LastUpdateTime           :string;
  ChangedBy                :string;
  MainNote                 :string;
  Reference                :number;
  SubReference             :number;
  Est_StartBegin           :string;
  Est_StartEnd             :string;
  Est_CompleteBeginTime    :string;
  Est_CompleteEndTime      :string;
  Act_StartTime            :string;
  Act_CompleteTime         :string;
  LastNoteNote             :string;
  LastNoteTime             :string;
  Status                   :string;
  Drivers                  :string;
  Booking                  :string;
  Commodity                :string;
  EquipmentReq             :string;
  Loadsize                 :string;
  Weight                    :string;
  Pieces                   :string;
  Cubes                    :string;
  Miles                     :string;
  Color                    :string;
  AltReference             :string;
  AddlRef                  :string;
  LinkRefNote              :string;
  LinkRef                  :number;
  PaperworkSent            :string;
  PaperworkRcvd            :string;
  BlindShipment            :string;
  NoteEnteredOnline        :string;
  NewRecord                :boolean;
  Posted                   :boolean;
  Post                     :boolean;
  ButtonColor              :string;
  Owner                    :string;
  BookDisp                 :string;
  TrackDisp                :string;
  Container                :string;
  Seal                     :string;
  Tag                      :string;
  Chassis                  :string;
  Spfilt                   :string;
  VendorName               :string;
  CustName                 :string;
  NetNote                  :string;
  CustPass                 :string;
  FrtExtra                 :string;
  CustomerRateType         :string;
  CustomerRateSummaryNote  :string;
  CustomerRateSummary      :string;
  CustomerExtraSummaryNote :string;
  CustomerExtraSummary     :string;
  CustomerTotalSummaryNote :string;
  CustomerTotalSummary     :string;
  VendorRateType           :string;
  VendorRateSummaryNote    :string;
  VendorRateSummary        :string;
  VendorExtraSummaryNote   :string;
  VendorExtraSummary       :string;
  VendorTotalSummaryNote   :string;
  VendorTotalSummary       :string;
  Valuenote                :string;
  Val                     :string;
  ApptReqNote              :string;
  ApptReq                  :boolean;
  ReadyNote                :string;
  Ready                    :boolean;
  Flag                     :boolean;
  CommFormula              :string;
  CommNote                 :string;
  QBType                   :string;
  Terms                    :string;
  PUNumbers                :string;
  PONumbers                :string;
  OrderNote                :string;
  Order                    :number;
  SpeedNote                :string;
  Speed                    :number;
  OmitNote                 :string;
  Omit                     :boolean;
  Billto                   :string;
  Billto_ID                :string;
  Customer_ID              :string;
  Invoice_ID               :string;
  Asset_ID                 :string;
  Commto1_ID               :string;
  Commto2_ID               :string;
  Commto3_ID               :string;
  JobStartLocation_ID      :string;
  JobEndLocation_ID        :string;
  Claim_ID                 :string;
  Claim2_ID                :string;
  selected                 :boolean;
  visible                  :boolean;
  select                   :boolean;
  active                   :boolean;
  state                    :string;
  fromCity                 :string;
  fromState                :string;
  toCity                   :string;
  toState                  :string;
  flatrated                :string;
  cflatrate                :string;
  vflatrate                :string;
  rate1d                   :string;
  rate1amt                 :string;
  crate1                   :string;
  vrate1                   :string;
  rate2d                   :string;
  rate2amt                 :string;
  crate2                   :string;
  vrate2                   :string;
  rate3d                   :string;
  rate3amt                 :string;
  crate3                   :string;
  vrate3                   :string;
  rate4d                   :string;
  rate4amt                 :string;
  crate4                   :string;
  vrate4                   :string;
  rate5d                   :string;
  rate5amt                 :string;
  crate5                   :string;
  vrate5                   :string;
  rate6d                   :string;
  rate6amt                 :string;
  crate6                   :string;
  vrate6                   :string;
  rate7d                   :string;
  rate7amt                 :string;
  crate7                   :string;
  vrate7                   :string;
  rate8d                   :string;
  rate8amt                 :string;
  crate8                   :string;
  vrate8                   :string;
  rate9d                   :string;
  rate9amt                 :string;
  crate9                   :string;
  vrate9                   :string;
  rate10d                  :string;
  rate10amt                :string;
  crate10                  :string;
  vrate10                  :string;
  custData: any;
  vendData: any;
  billData: any;


constructor() {
  this.JobID = '';
  this.FromID = '';
  this.SubJobs = '';
  this.SubAssets = '';
  this.Compcode = '';
  this.Group1 = '';
  this.Group2 = '';
  this.Type = '';
  this.Subtype = '';
  this.CSS = '';
  this.IconURL = '';
  this.Heading = '';
  this.Hidden = false;
  this.CreateTime = '';
  this.Enteredby = '';
  this.LastUpdateTime = '';
  this.ChangedBy = '';
  this.MainNote = '';
  this.Reference = 0;
  this.SubReference = 0;
  this.Est_StartBegin = '';
  this.Est_StartEnd = '';
  this.Est_CompleteBeginTime = '';
  this.Est_CompleteEndTime = '';
  this.Act_StartTime = '';
  this.Act_CompleteTime = '';
  this.LastNoteNote = '';
  this.LastNoteTime = '';
  this.Status = '';
  this.Drivers = '';
  this.Booking = '';
  this.Commodity = '';
  this.EquipmentReq = '';
  this.Loadsize = '';
  this.Weight = '0';
  this.Pieces = '0';
  this.Cubes = '0';
  this.Miles  = '0';
  this.Color = '';
  this.AltReference = '';
  this.AddlRef = '';
  this.LinkRefNote = '';
  this.LinkRef = 0;
  this.PaperworkSent = '';
  this.PaperworkRcvd = '';
  this.BlindShipment = '';
  this.NoteEnteredOnline = '';
  this.NewRecord = false;
  this.Posted = false;
  this.Post = false;
  this.ButtonColor = '';
  this.Owner = '';
  this.BookDisp = '';
  this.TrackDisp = '';
  this.Container = '';
  this.Seal = '';
  this.Tag = '';
  this.Chassis = '';
  this.Spfilt = '';
  this.VendorName = '';
  this.CustName = '';
  this.NetNote = '';
  this.CustPass = '';
  this.FrtExtra = '';
  this.CustomerRateType = '';
  this.CustomerRateSummaryNote = '';
  this.CustomerRateSummary = '';
  this.CustomerExtraSummaryNote = '';
  this.CustomerExtraSummary = '';
  this.CustomerTotalSummaryNote = '';
  this.CustomerTotalSummary = '';
  this.VendorRateType = '';
  this.VendorRateSummaryNote = '';
  this.VendorRateSummary = '';
  this.VendorExtraSummaryNote = '';
  this.VendorExtraSummary = '';
  this.VendorTotalSummaryNote = '';
  this.VendorTotalSummary = '';
  this.Valuenote = '';
  this.Val = '';
  this.ApptReqNote = '';
  this.ApptReq = false;
  this.ReadyNote = '';
  this.Ready = false;
  this.Flag = false;
  this.CommFormula = '';
  this.CommNote = '';
  this.QBType = '';
  this.Terms = '';
  this.PUNumbers = '';
  this.PONumbers = '';
  this.OrderNote = '';
  this.Order = 0;
  this.SpeedNote = '';
  this.Speed = 0;
  this.OmitNote = '';
  this.Omit = false;
  this.Billto = 'Customer';
  this.Billto_ID = '';
  this.Customer_ID = '';
  this.Invoice_ID = '';
  this.Asset_ID = '';
  this.Commto1_ID = '';
  this.Commto2_ID = '';
  this.Commto3_ID = '';
  this.JobStartLocation_ID = '';
  this.JobEndLocation_ID = '';
  this.Claim_ID = '';
  this.Claim2_ID = '';
  this.selected = false;
  this.visible = false;
  this.select = false;
  this.active = false;
  this.state = 'V';
  this.fromCity = '';
  this.fromState = '';
  this.toCity = '';
  this.toState = '';
  this.flatrated = 'Flat Rate';
  this.cflatrate = '';
  this.vflatrate = '';
  this.rate1d = 'Hub Miles';
  this.rate1amt = '';
  this.crate1 = '';
  this.vrate1 = '';
  this.rate2d = 'Miles';
  this.rate2amt = '';
  this.crate2 = '';
  this.vrate2 = '';
  this.rate3d = 'Weight';
  this.rate3amt = '';
  this.crate3 = '';
  this.vrate3 = '';
  this.rate4d = 'Fuel Surch';
  this.rate4amt = '';
  this.crate4 = '';
  this.vrate4 = '';
  this.rate5d = 'Pieces';
  this.rate5amt = '';
  this.crate5 = '';
  this.vrate5 = '';
  this.rate6d = 'Loading';
  this.rate6amt = '';
  this.crate6 = '';
  this.vrate6 = '';
  this.rate7d = 'Stops';
  this.rate7amt = '';
  this.crate7 = '';
  this.vrate7 = '';
  this.rate8d = 'Pallets';
  this.rate8amt = '';
  this.crate8 = '';
  this.vrate8 = '';
  this.rate9d = 'Other 1';
  this.rate9amt = '';
  this.crate9 = '';
  this.vrate9 = '';
  this.rate10d = 'Other 2';
  this.rate10amt = '';
  this.crate10 = '';
  this.vrate10 = '';
  this.custData = {};
  this.vendData = {};
  this.billData = {};

  }

  setDefaultHeading() {
    this.SubJobs = 'Sub Jobs';
    this.SubAssets = 'Sub Assets';
    this.Compcode = 'Comp Code';
    this.Group1 = 'Grp 1';
    this.Group2 = 'Grp 2';
    this.Type = 'Type';
    this.Subtype = 'Sub Type';
    this.CSS = 'CSS';
    this.IconURL = 'Icon Url';
    this.Heading = 'Heading';
    this.CreateTime = 'Created';
    this.Enteredby = 'Entered By';
    this.LastUpdateTime = 'Updated';
    this.ChangedBy = 'Changed By';
    this.MainNote = 'Note';
    this.Est_StartBegin = 'Start Begin';
    this.Est_StartEnd = 'Start End';
    this.Est_CompleteBeginTime = 'Complete Begin';
    this.Est_CompleteEndTime = 'Complete End';
    this.Act_StartTime = 'Actual Start';
    this.Act_CompleteTime = 'Actual End';
    this.LastNoteNote = 'Last Note';
    this.LastNoteTime = 'Last Note Time';
    this.Status = 'Status';
    this.Drivers = 'Drivers';
    this.Booking = 'Booking';
    this.Commodity = 'Commodity';
    this.EquipmentReq = 'Equipment Req';
    this.Loadsize = 'Load Size';
    this.Weight = 'Weight';
    this.Pieces = 'Pieces';
    this.Cubes = 'Cubes';
    this.Miles  = 'Miles';
    this.Color = 'Color';
    this.AltReference = 'Alt Reference';
    this.AddlRef = 'Addl Reference';
    this.LinkRefNote = 'Link Ref';
    this.PaperworkSent = 'Paperwork Sent';
    this.PaperworkRcvd = 'Paperwork Rcvd';
    this.BlindShipment = 'Blind Ship';
    this.NoteEnteredOnline = 'Online Note';
    this.ButtonColor = 'Button Color';
    this.Owner = 'Owner';
    this.BookDisp = 'Booking Disp';
    this.TrackDisp = 'Tracking Disp';
    this.Container = 'Container';
    this.Seal = 'Seal';
    this.Tag = 'Tag';
    this.Chassis = 'Chassis';
    this.Spfilt = 'Filter';
    this.VendorName = 'Vendor';
    this.CustName = 'Customer';
    this.NetNote = 'Net Note';
    this.CustPass = 'Cust Password';
    this.FrtExtra = 'Freight Xtra';
    this.CustomerRateType = 'Cust Rate Type';
    this.CustomerRateSummaryNote = 'Cust Rate Summary';
    this.CustomerRateSummary = 'Cust Rate';
    this.CustomerExtraSummaryNote = 'Cust Extra Note';
    this.CustomerExtraSummary = 'Cust Extra Summary';
    this.CustomerTotalSummaryNote = 'Cust Total Summary Note';
    this.CustomerTotalSummary = 'Customer Total Summary';
    this.VendorRateType = 'Vendor Rate Type';
    this.VendorRateSummaryNote = 'Verndor Rate Summary Note';
    this.VendorRateSummary = 'Vendor Rate Summary';
    this.VendorExtraSummaryNote = 'Vendor Extra Summary Note';
    this.VendorExtraSummary = 'Vendor Extra Summary';
    this.VendorTotalSummaryNote = 'Vendor Total Summary Note';
    this.VendorTotalSummary = 'Vendor Total Summary';
    this.Valuenote = 'Value Note';
    this.Val = 'Value';
    this.ApptReqNote = 'Appt Req';
    this.ReadyNote = 'Ready';
    this.CommFormula = 'Comm Formula';
    this.CommNote = 'Comm Note';
    this.QBType = 'QB Type';
    this.Terms = 'Terms';
    this.PUNumbers = 'PU Numbers';
    this.PONumbers = 'PO Numbers';
    this.OrderNote = 'Order';
    this.SpeedNote = 'Speed';

    this.OmitNote = 'Omit';
    this.Billto = 'Bill To';
    this.Billto_ID = '';
    this.Customer_ID = '';
    this.Invoice_ID = '';
    this.Asset_ID = '';
    this.Commto1_ID = '';
    this.Commto2_ID = '';
    this.Commto3_ID = '';
    this.JobStartLocation_ID = '';
    this.JobEndLocation_ID = '';
    this.Claim_ID = '';
    this.Claim2_ID = '';
    this.fromCity = 'From City';
    this.fromState = 'From State';
    this.toCity = 'To City';
    this.toState = 'To State';
    this.flatrated = 'Flat Rate';
    this.cflatrate = '';
    this.vflatrate = '';
    this.rate1d = 'Hub Miles';
    this.rate1amt = '';
    this.crate1 = '';
    this.vrate1 = '';
    this.rate2d = 'Miles';
    this.rate2amt = '';
    this.crate2 = '';
    this.vrate2 = '';
    this.rate3d = 'Weight';
    this.rate3amt = '';
    this.crate3 = '';
    this.vrate3 = '';
    this.rate4d = 'Fuel Surch';
    this.rate4amt = '';
    this.crate4 = '';
    this.vrate4 = '';
    this.rate5d = 'Pieces';
    this.rate5amt = '';
    this.crate5 = '';
    this.vrate5 = '';
    this.rate6d = 'Loading';
    this.rate6amt = '';
    this.crate6 = '';
    this.vrate6 = '';
    this.rate7d = 'Stops';
    this.rate7amt = '';
    this.crate7 = '';
    this.vrate7 = '';
    this.rate8d = 'Pallets';
    this.rate8amt = '';
    this.crate8 = '';
    this.vrate8 = '';
    this.rate9d = 'Other 1';
    this.rate9amt = '';
    this.crate9 = '';
    this.vrate9 = '';
    this.rate10d = 'Other 2';
    this.rate10amt = '';
    this.crate10 = '';
    this.vrate10 = '';
    }

  set(init?: Partial<Job>) {
      Object.assign(this, init);
  }

  toPlainObj() {
      return Object.assign({}, this);
  }
}
