export class Config  {
  configID                 :string;
  description               : string;
  configNumber             :number;
  lastTempNum              :number;
  lastRealNum              :number;
  vendorAgree              :string;
  customerAgree            :string;
  invoiceNote              :string;
  vendorConfirmNote        :string;
  custConfirmNotes         :string;
  companyEmail             :string;
  companySmtpAddress       :string;
  companySMTPPort          :string;
  companyEmailUser         :string;
  companyEmailPass         :string;
  storageLocation          :string;
  logoFile                 :string;
  defaultCustCredit        :number;
  useTempNegative          :boolean;
  autoCalcCommissions      :boolean;
  CreateTime               :string;
  LastUpdateTime           :string;
  DisplayName              :string;
  visible                  :boolean;
  selected                 :boolean;
  state                    :string;


constructor() {
  this.configID = '';
  this.description = '';
  this.configNumber =0;
  this.lastTempNum = 0;
  this.lastRealNum = 0;
  this.vendorAgree = '';
  this.customerAgree = '';
  this.invoiceNote = '';
  this.vendorConfirmNote = '';
  this.custConfirmNotes = '';
  this.companyEmail = '';
  this.companySmtpAddress = '';
  this.companySMTPPort = '';
  this.companyEmailUser = '';
  this.companyEmailPass = '';
  this.storageLocation = '';
  this.logoFile = '';
  this.defaultCustCredit = 0;
  this.useTempNegative = false;
  this.autoCalcCommissions = false;
  }

  set(init?: Partial<Config>) {
      Object.assign(this, init);
  }

  toPlainObj() {
      return Object.assign({}, this);
  }

  setDefaultHeading() {
    this.configID = 'Configid';
    this.description = 'Description';
    this.vendorAgree = 'Vendor Agreement';
    this.customerAgree = 'Customer Agreement';
    this.invoiceNote = 'Invoice Note';
    this.vendorConfirmNote = 'Vendor Confirm note';
    this.custConfirmNotes = 'Cust Confirm Note';
    this.companyEmail = 'Company Email';
    this.companySmtpAddress = 'Company SMTP Address';
    this.companySMTPPort = 'Company SMTP Port';
    this.companyEmailUser = 'Company Email Username';
    this.companyEmailPass = 'Company Email Password';
    this.storageLocation = 'Storage Location';
    this.logoFile = 'Logo File';
  }
}
