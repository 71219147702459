export class Notes  {
  notesId                  :string;
  FromID                   :string;
  Compcode                 :string;
  Group1                   :string;
  Group2                   :string;
  Type                     :string;
  Subtype                  :string;
  Template                 :number;
  CSS                      :string;
  IconURL                  :string;
  Timestamp                :string;
  Heading                  :string;
  Hidden                   :boolean;
  CreateTime               :string;
  Enteredby                :string;
  LastUpdateTime           :string;
  ChangedBy                :string;
  TheNote                  :string;
  Status                   :string;
  Alert                    :boolean;
  AlertNote                :string;
  AlertTime                :string;
  AlertTimeNote            :string;
  Est_CompleteBeginNote    :string;
  Est_CompleteBegin        :string;
  Est_CompleteEndNote      :string;
  Est_CompleteEnd          :string;
  Act_StartNote            :string;
  Act_Start                :string;
  Act_CompleteNote         :string;
  Act_Complete             :string;
  Longitude                :string;
  Lattitude                :string;
  Altitude                 :string;
  Accuracy                 :string;
  selected                 :boolean;
  visible                  :boolean;

constructor() {
  this.notesId = '';
  this.FromID = '';
  this.Compcode = '';
  this.Group1 = '';
  this.Group2 = '';
  this.Type = '';
  this.Subtype = '';
  this.Template = 0;
  this.CSS = '';
  this.IconURL = '';
  this.Timestamp = '';
  this.Heading = '';
  this.Hidden = false;
  this.CreateTime = '';
  this.Enteredby = '';
  this.LastUpdateTime = '';
  this.ChangedBy = '';
  this.TheNote = '';
  this.Status = '';
  this.Alert = false;
  this.AlertNote = '';
  this.AlertTime = '';
  this.AlertTimeNote = '';
  this.Est_CompleteBeginNote = '';
  this.Est_CompleteBegin = '';
  this.Est_CompleteEndNote = '';
  this.Est_CompleteEnd = '';
  this.Act_StartNote = '';
  this.Act_Start = '';
  this.Act_CompleteNote = '';
  this.Act_Complete = '';
  this.Longitude = '';
  this.Lattitude = '';
  this.Altitude = '';
  this.Accuracy = '';
  this.selected = false;
  this.visible = false;
  }

  set(init?: Partial<Notes>) {
      Object.assign(this, init);
  }

  toPlainObj() {
      return Object.assign({}, this);
  }

setDefaultHeading() {
  this.notesId = 'Note ID';
  this.FromID = 'From ID';
  this.Compcode = 'Company';
  this.Group1 = 'Group 1';
  this.Group2 = 'Group 2';
  this.Type = 'Type';
  this.Subtype = 'SubType';
  this.Template = 0;
  this.CSS = 'CSS';
  this.IconURL = 'IconURL';
  this.Timestamp = 'Timestamp';
  this.Heading = 'Heading';
  this.Hidden = false;
  this.CreateTime = 'Create Time';
  this.Enteredby = 'By';
  this.LastUpdateTime = 'Updated';
  this.ChangedBy = 'Change';
  this.TheNote = 'Note';
  this.Status = 'Status';
  this.Alert = false;
  this.AlertNote = 'Alert';
  this.AlertTime = 'Alert Time';
  this.AlertTimeNote = 'Note';
  this.Est_CompleteBeginNote = 'Est Begin';
  this.Est_CompleteBegin = 'Est Comp End';
  this.Est_CompleteEndNote = 'Est Completed';
  this.Est_CompleteEnd = 'Est Comp End';
  this.Act_StartNote = 'Act Start';
  this.Act_Start = 'Act Start Time';
  this.Act_CompleteNote = 'Act End Note';
  this.Act_Complete = 'Act End';
  this.Longitude = 'Longitude';
  this.Lattitude = 'Latitude';
  this.Altitude = 'Altitude';
  this.Accuracy = 'Accuracy';
  this.selected = false;
  this.visible = false;
}

}

