
import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router,
              private auth: AuthenticationService) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    console.log(route);
    const user = await this.auth.getCurrentUserId();
    if (user.length == 0) {
      this.router.navigate(["login"]);
      return false;
    }
    return true;
  }
}
