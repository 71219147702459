import { ɵCompiler_compileModuleAndAllComponentsSync__POST_R3__ } from '@angular/core';

export class Name {
    nameId          : string; // the firestore assigned ID
    Lookup          : string; // type plus displayname
    Type            : string; // 5 digit string designates lookup type 00000 Employee 00001 Customer 00002 Vendor 00003
    status          : string; // freeform string uppercase ie: ACTIVE, PENDING, HOLD
    status2         : string; // secondary same as status
    visible         : boolean;
    active          : boolean;
    selected        : boolean;

    // Quickbooks fields
    QBID            : string;
    DisplayName     : string; // if not supplied Title, GivenName, MiddleName, FamilyName, and Suffix.
    Title           : string;
    GivenName       : string;
    MiddleName      : string;
    Suffix          : string;
    FamilyName      : string;
    CreateTime      : string;
    LastUpdatedTime : string;
    Address         : string;
    Address2        : string;
    Zip             : string;
    Country         : string;
    // Display Fields
    City            : string;
    State           : string;
    PrimaryPhone    : string;
    PrimaryEmailAddr: string;

    FromID          : string;
    Compcode        : string;
    Group1          : string;
    Group2          : string;
    Subtype         : string;
    Template        : string;
    CSS             : string;
    IconURL         : string;
    Timestamp       : string;
    Heading         : string;
    Hidden          : boolean;  // EQUIV OF DELETE
    Entered         : string;
    Enteredby       : string;
    LastChange      : string;
    ChangedBy       : string;
    MainNote        : string;
    Group           : string;
    Owner           : string;
    Status          : string;
    Name            : string;
    Address3        : string;
    AltCity         : string;
    AltState        : string;
    PostalCode      : string;
    Region          : string;
    Recipient       : string;
    Contact1        : string;
    Title1          : string;
    Phone1          : string;
    Email1          : string;
    Fax1            : string;
    Website1        : string;
    Messenger1      : string;
    CellPhone1      : string;
    Contact2        : string;
    Title2          : string;
    Phone2          : string;
    Email2          : string;
    Fax2            : string;
    Website2        : string;
    Messenger2      : string;
    Cellphone2      : string;
    ConnectString   : string;
    IPAddress       : string;
    Longitude       : string;
    Lattitude       : string;
    Altitude        : string;
    Accuracy        : string;
    Terms           : string;
    Currency        : string;
    Rating          : string;
    TaxID           : string;
    Send1099        : boolean;
    NewRecord       : boolean;
    Billing_ID      : string;
    state           : string;

    constructor() {
      this.clearName();
    }

    private clearName() {
        this.nameId = '';
        this.Lookup = ''; // type plus DisplayName
        this.Type = ''; // 5 digit string designates lookup type 00000 Employee 00001 Customer 00002 Vendor 00003
        this.status = '';
        this.status2 = '';
        this.visible = true;
        this.active = true;
        this.selected = false;

        // Quickbooks fields
        this.QBID = '';
        this.DisplayName = ''; // if not supplied Title, GivenName, MiddleName, FamilyName, and Suffix.
        this.Title = '';
        this.GivenName = '';
        this.MiddleName = '';
        this.Suffix = '';
        this.FamilyName = '';
        this.CreateTime = new Date().toISOString();
        this.LastUpdatedTime = new Date().toISOString();
        this.Address= '';
        this.Address2 = '';
        this.Zip = '';
        this.Country = '';
        // Display Fields
        this.City = '';
        this.State = '';
        this.PrimaryPhone = ''; //
        this.PrimaryEmailAddr = '';

        this.FromID = '';
        this.Compcode = '';
        this.Group1 = '';
        this.Group2 = '';
        this.Type = '';
        this.Subtype = '';
        this.Template = '';
        this.CSS = '';
        this.IconURL = '';
        this.Timestamp = '';
        this.Heading = '';
        this.Hidden = false;
        this.Entered = '';
        this.Enteredby = '';
        this.LastChange = '';
        this.ChangedBy = '';
        this.MainNote = '';
        this.Group = '';
        this.Owner = '';
        this.Status = '';
        this.Name = '';
        this.Address3 = '';
        this.AltCity = '';
        this.AltState = '';
        this.PostalCode = '';
        this.Region = '';
        this.Recipient = '';
        this.Contact1 = '';
        this.Title1 = '';
        this.Phone1 = '';
        this.Email1 = '';
        this.Fax1 = ''; //
        this.Website1 = '';
        this.Messenger1 = '';
        this.CellPhone1 = ''; //
        this.Contact2 = '';
        this.Title2 = '';
        this.Phone2 = ''; //
        this.Email2 = '';
        this.Fax2 = ''; //
        this.Website2 = '';
        this.Messenger2 = '';
        this.Cellphone2 = ''; //
        this.ConnectString = '';
        this.IPAddress = '';
        this.Longitude = '';
        this.Lattitude = '';
        this.Altitude = '';
        this.Accuracy = '';
        this.Terms = '';
        this.Currency = '';
        this.Rating = '';
        this.TaxID = '';
        this.Send1099 = false;
        this.NewRecord = false;
        this.Billing_ID = '';
        this.state = 'D';
    }

 setDefaultHeading() {
      this.nameId     = 'nameId'; // the firestore assigned ID
      this.Lookup          = 'Lookup'; // ty'pe plus displayname
      this.Type            = 'Type'; // 5 digit string designates lookup type 00000 Employee 00001 Customer 00002 Vendor 00003
      this.status          = 'Status'; // freeform string uppercase ie= ACTIVE; PENDING; HOLD
      this.status2         = 'Status 2'; // secondary same as status
      this.visible         = false;
      this.active          = true;
      this.selected        = false;

      // Quickbooks fields
      this.QBID            = 'QB ID';
      this.DisplayName     = 'Display'; // if not supplied Title; GivenName; MiddleName; FamilyName; and Suffix.
      this.Title           = 'Title';
      this.GivenName       = 'First Name';
      this.MiddleName      = 'Middle';
      this.Suffix          = 'Suffix';
      this.FamilyName      = 'Last Name';
      this.CreateTime      = 'Created';
      this.LastUpdatedTime = 'Last Updated';
      this.Address         = 'Address';
      this.Address2        = 'Address 2';
      this.Zip             = 'Zip Code';
      this.Country         = 'Country';
      // Display Fields
      this.City            = 'City';
      this.State           = 'State';
      this.PrimaryPhone    = 'Phone';
      this.PrimaryEmailAddr= 'Email';

      this.FromID         ='From ID';
      this.Compcode       ='Comp Code';
      this.Group1         = 'Group 1';
      this.Group2         = 'Group 2';
      this.Subtype        = 'Sub Type';
      this.Template       = 'Template';
      this.CSS            = 'CSS';
      this.IconURL        = 'Icon URL';
      this.Timestamp      = 'Timestamp';
      this.Heading        = 'Heading';
      this.Hidden         = false;
      this.Entered        = 'Entered';
      this.Enteredby      = 'Entered By';
      this.LastChange     = 'Last Change';
      this.ChangedBy      = 'Changed By';
      this.MainNote       = 'Note';
      this.Group          = 'Group';
      this.Owner          = 'Owner';
      this.Status         = 'Status';
      this.Name           = 'Name';
      this.Address3       = 'Addr 3';
      this.AltCity        = 'Alt City';
      this.AltState       = 'Alt State';
      this.PostalCode     = 'Postal Code';
      this.Region         = 'Region';
      this.Recipient      = 'Recipient';
      this.Contact1       = 'Contact 1';
      this.Title1         = 'Title';
      this.Phone1         = 'Phone 1';
      this.Email1         = 'Email 1';
      this.Fax1           = 'Fax';
      this.Website1       = 'Website';
      this.Messenger1     = 'Messenger 1';
      this.CellPhone1     = 'Mobile 1';
      this.Contact2       = 'Contact 2';
      this.Title2         = 'Title 2';
      this.Phone2         = 'Phone 2';
      this.Email2         = 'Email 2';
      this.Fax2           = 'Fax 2';
      this.Website2       = 'Website 2';
      this.Messenger2     = 'Messenger 2';
      this.Cellphone2     = 'Mobile 2';
      this.ConnectString  = 'Connect String';
      this.IPAddress      = 'IP Address';
      this.Longitude      = 'Longitude';
      this.Lattitude      = 'Lattitude';
      this.Altitude       = 'Altitude';
      this.Accuracy       = 'Accuracy';
      this.Terms          = 'Terms';
      this.Currency       = 'Currency';
      this.Rating         = 'Rating';
      this.TaxID          = 'Tax Id';
      this.Send1099       = false;
      this.NewRecord      = false;
      this.Billing_ID     = 'Billing ID';
    }

    set(init?: Partial<Name>) {
      Object.assign(this, init);
  }

    toPlainObj() {
      return Object.assign({}, this);
    }
}


// ***** Quickbooks Online Customer *****
// "Customer": {
//     "PrimaryEmailAddr": {
//       "Address": "Surf@Intuit.com"
//     },
//     "SyncToken": "0",
//     "domain": "QBO",
//     "GivenName": "Bill",
//     "DisplayName": "Bill's Windsurf Shop",
//     "BillWithParent": false,
//     "FullyQualifiedName": "Bill's Windsurf Shop",
//     "CompanyName": "Bill's Windsurf Shop",
//     "FamilyName": "Lucchini",
//     "sparse": false,
//     "PrimaryPhone": {
//       "FreeFormNumber": "(415) 444-6538"
//     },
//     "Active": true,
//     "Job": false,
//     "BalanceWithJobs": 85.0,
//     "BillAddr": {
//       "City": "Half Moon Bay",
//       "Line1": "12 Ocean Dr.",
//       "PostalCode": "94213",
//       "Lat": "37.4307072",
//       "Long": "-122.4295234",
//       "CountrySubDivisionCode": "CA",
//       "Id": "3"
//     },
//     "PreferredDeliveryMethod": "Print",
//     "Taxable": false,
//     "PrintOnCheckName": "Bill's Windsurf Shop",
//     "Balance": 85.0,
//     "Id": "2",
//     "MetaData": {
//       "CreateTime": "2014-09-11T16:49:28-07:00",
//       "LastUpdatedTime": "2014-09-18T12:56:01-07:00"
//     }
//   },
//   "time": "2015-07-23T11:04:15.496-07:00"
// }

// =Id
// * Required for update
// read only
// system defined
// String , filterable , sortable
// Unique identifier for this object. Sort order is ASC by default.

// SyncToken
// * Required for update
// read only
// system defined
// String
// Version number of the object. It is used to lock an object for use by one app at a time. As soon as an application modifies an object, its SyncToken is incremented. Attempts to modify an object specifying an older SyncToken fails. Only the latest version of the object is maintained by QuickBooks Online.

// =DisplayName
// * Conditionally required
// max character: maximum of 500 chars
// String , filterable , sortable
// The name of the person or organization as displayed. Must be unique across all Customer, Vendor, and Employee objects. Cannot be removed with sparse update. If not supplied, the system generates DisplayName by concatenating customer name components supplied in the request from the following list: Title, GivenName, MiddleName, FamilyName, and Suffix.

// =Title
// * Conditionally required
// max character: maximum of 16 chars
// String
// Title of the person. This tag supports i18n, all locales. The DisplayName attribute or at least one of Title, GivenName, MiddleName, FamilyName, or Suffix attributes is required.

// =GivenName
// * Conditionally required
// max character: maximum of 100 chars
// String , filterable , sortable
// Given name or first name of a person. The DisplayName attribute or at least one of Title, GivenName, MiddleName, FamilyName, or Suffix attributes is required.

// =MiddleName
// * Conditionally required
// max character: maximum of 100 chars
// String , filterable , sortable
// Middle name of the person. The person can have zero or more middle names. The DisplayName attribute or at least one of Title, GivenName, MiddleName, FamilyName, or Suffix attributes is required.

// =Suffix
// * Conditionally required
// max character: maximum of 16 chars
// String
// Suffix of the name. For example, Jr. The DisplayName attribute or at least one of Title, GivenName, MiddleName, FamilyName, or Suffix attributes is required.

// =FamilyName
// * Conditionally required
// max character: maximum of 100 chars
// String , filterable , sortable
// Family name or the last name of the person. The DisplayName attribute or at least one of Title, GivenName, MiddleName, FamilyName, or Suffix attributes is required.

// PrimaryEmailAddr
// Optional
// EmailAddress , filterable
// Primary email address.
// Show child attributes

// ResaleNum
// Optional
// max character: 16 chars
// String
// Resale number or some additional info about the customer.

// SecondaryTaxIdentifier
// Optional
// minorVersion: 3
// String
// Also called UTR No. in ( UK ) , CST Reg No. ( IN ) also represents the tax registration number of the Person or Organization. This value is masked in responses, exposing only last five characters. For example, the ID of 123-45-6789 is returned as XXXXXX56789.

// ARAccountRef
// Optional
// minorVersion: 3
// ReferenceType
// Identifies the accounts receivable account to be used for this customer. Each customer must have his own AR account. Applicable for France companies, only. Available when endpoint is evoked with the minorversion=3 query parameter. Query the Account name list resource to determine the appropriate Account object for this reference, where Account.AccountType=Accounts Receivable. Use Account.Id and Account.Name from that object for ARAccountRef.value and ARAccountRef.name, respectively.
// Show child attributes

// DefaultTaxCodeRef
// Optional
// ReferenceType
// Reference to a default tax code associated with this Customer object. Reference is valid if Customer.Taxable is set to true; otherwise, it is ignored. If automated sales tax is enabled (Preferences.TaxPrefs.PartnerTaxEnabled is set to true) the default tax code is set by the system and can not be overridden. Query the TaxCode name list resource to determine the appropriate TaxCode object for this reference. Use TaxCode.Id and TaxCode.Name from that object for DefaultTaxCodeRef.value and DefaultTaxCodeRef.name, respectively.
// Show child attributes

// PreferredDeliveryMethod
// Optional
// String
// Preferred delivery method. Values are Print, Email, or None.

// GSTIN
// Optional
// max character: maximum of 15 chars
// minorVersion: 33
// String
// GSTIN is an identification number assigned to every GST registered business.

// SalesTermRef
// Optional
// ReferenceType
// Reference to a SalesTerm associated with this Customer object. Query the Term name list resource to determine the appropriate Term object for this reference. Use Term.Id and Term.Name from that object for SalesTermRef.value and SalesTermRef.name, respectively.
// Show child attributes

// CustomerTypeRef
// Optional
// String
// Reference to the customer type assigned to a customer. This field is only returned if the customer is assigned a customer type.
// Show child attributes

// Fax
// Optional
// max character: maximum of 30 chars
// TelephoneNumber
// Fax number.
// Show child attributes

// BusinessNumber
// Optional
// max character: maximum of 10 chars
// minorVersion: 33
// String
// Also called, PAN (in India) is a code that acts as an identification for individuals, families and corporates, especially for those who pay taxes on their income.

// BillWithParent
// Optional
// Boolean
// If true, this Customer object is billed with its parent. If false, or null the customer is not to be billed with its parent. This attribute is valid only if this entity is a Job or sub Customer.

// CurrencyRef
// Optional
// max character: 16 chars
// read only
// CurrencyRef
// Reference to the currency in which all amounts associated with this customer are expressed. Once set, it cannot be changed. If specified currency is not currently in the company's currency list, it is added. If not specified, currency for this customer is the home currency of the company, as defined by Preferences.CurrencyPrefs.HomeCurrency. [[ String Preferred delivery method. Values are Print, Email, or None. String, 16 chars Resale number or some additional info about the customer. ReferenceType Identifies the accounts receivable account to be used for this customer. Each customer must have his own AR account. Applicable for France companies, only. Available when endpoint is evoked with the minorversion=3 query parameter. Query the Account name list resource to determine the appropriate Account object for this reference, where Account.AccountType=Accounts Receivable. Use Account.Id and Account.Name from that object for ARAccountRef.value and ARAccountRef.name, respectively. Read-only after object is created
// Show child attributes

// Mobile
// Optional
// max character: maximum of 30 chars
// TelephoneNumber
// Mobile phone number.
// Show child attributes

// Job
// Optional
// Boolean
// If true, this is a Job or sub-customer. If false or null, this is a top level customer, not a Job or sub-customer.

// BalanceWithJobs
// Optional
// Decimal , sortable
// Cumulative open balance amount for the Customer (or Job) and all its sub-jobs. Cannot be written to QuickBooks.

// PrimaryPhone
// Optional
// max character: maximum of 30 chars
// TelephoneNumber
// Primary phone number.
// Show child attributes

// OpenBalanceDate
// Optional
// Date
// Date of the Open Balance for the create operation. Write-on-create.
// Show child attributes

// Taxable
// Optional
// Boolean
// If true, transactions for this customer are taxable. Default behavior with minor version 10 and above: true, if DefaultTaxCodeRef is defined or false if TaxExemptionReasonId is set.

// AlternatePhone
// Optional
// max character: maximum of 30 chars
// TelephoneNumber
// Alternate phone number.
// Show child attributes

// MetaData
// Optional
// ModificationMetaData
// Descriptive information about the entity. The MetaData values are set by Data Services and are read only for all applications.
// Show child attributes

// ParentRef
// Optional
// ReferenceType
// A reference to a Customer object that is the immediate parent of the Sub-Customer/Job in the hierarchical Customer:Job list. Required for the create operation if this object is a sub-customer or Job. Query the Customer name list resource to determine the appropriate Customer object for this reference. Use Customer.Id and Customer.DisplayName from that object for ParentRef.value and ParentRef.name, respectively.
// Show child attributes

// Notes
// Optional
// max character: maximum of 2000 chars
// String
// Free form text describing the Customer.

// WebAddr
// Optional
// max character: maximum of 1000 chars
// WebSiteAddress
// Website address.
// Show child attributes

// Active
// Optional
// Boolean , filterable , sortable
// If true, this entity is currently enabled for use by QuickBooks. If there is an amount in Customer.Balance when setting this Customer object to inactive through the QuickBooks UI, a CreditMemo balancing transaction is created for the amount.

// Balance
// Optional
// Decimal , filterable , sortable
// Specifies the open balance amount or the amount unpaid by the customer. For the create operation, this represents the opening balance for the customer. When returned in response to the query request it represents the current open balance (unpaid amount) for that customer. Write-on-create.

// ShipAddr
// Optional
// PhysicalAddress
// Default shipping address. If a physical address is updated from within the transaction object, the QuickBooks Online API flows individual address components differently into the Line elements of the transaction response then when the transaction was first created:
// Line1 and Line2 elements are populated with the customer name and company name.
// Original Line1 through Line 5 contents, City, SubDivisionCode, and PostalCode flow into Line3 through Line5as a free format strings.
// Show more details
// Show child attributes

// PaymentMethodRef
// Optional
// ReferenceType
// Reference to a PaymentMethod associated with this Customer object. Query the PaymentMethod name list resource to determine the appropriate PaymentMethod object for this reference. Use PaymentMethod.Id and PaymentMethod.Name from that object for PaymentMethodRef.value and PaymentMethodRef.name, respectively.
// Show child attributes

// IsProject
// Optional
// minorVersion: 25
// Boolean
// If true, indicates this is a Project.

// CompanyName
// Optional
// max character: maximum of 100 chars
// String , filterable , sortable
// The name of the company associated with the person or organization.

// PrimaryTaxIdentifier
// Optional
// minorVersion: 4
// String
// Also called Tax Reg. No in ( UK ) , ( CA ) , ( IN ) , ( AU ) represents the tax ID of the Person or Organization. This value is masked in responses, exposing only last five characters. For example, the ID of 123-45-6789 is returned as XXXXXX56789.

// GSTRegistrationType
// Optional
// max character: maximum of 15 chars
// minorVersion: 33
// String
// For the filing of GSTR, transactions need to be classified depending on the type of customer to whom the sale is done. To facilitate this, we have introduced a new field as 'GST registration type'. Possible values are listed below:
// GST_REG_REG GST registered- Regular. Customer who has a business which is registered under GST and has a GSTIN (doesn’t include customers registered under composition scheme, as an SEZ or as EOU's, STP's EHTP's etc.).
// GST_REG_COMP GST registered-Composition. Customer who has a business which is registered under the composition scheme of GST and has a GSTIN.
// GST_UNREG GST unregistered. Customer who has a business which is not registered under GST and does not have a GSTIN.
// CONSUMER Consumer. Customer who is not registered under GST and is the final consumer of the service or product sold.
// OVERSEAS Overseas. Customer who has a business which is located out of India.
// SEZ SEZ. Customer who has a business which is registered under GST, has a GSTIN and is located in a SEZ or is a SEZ Developer.
// DEEMED Deemed exports- EOU's, STP's EHTP's etc. Customer who has a business which is registered under GST and falls in the category of companies (EOU's, STP's EHTP's etc.), to which supplies are made they are termed as deemed exports.

// PrintOnCheckName
// Optional
// max character: maximum of 110 chars
// String , filterable , sortable
// Name of the person or organization as printed on a check. If not provided, this is populated from DisplayName. Constraints: Cannot be removed with sparse update.

// BillAddr
// Optional
// PhysicalAddress
// Default billing address. If a physical address is updated from within the transaction object, the QuickBooks Online API flows individual address components differently into the Line elements of the transaction response then when the transaction was first created:
// Line1 and Line2 elements are populated with the customer name and company name.
// Original Line1 through Line 5 contents, City, SubDivisionCode, and PostalCode flow into Line3 through Line5as a free format strings.
// Show more details
// Show child attributes

// FullyQualifiedName
// read only
// system defined
// String , filterable , sortable
// Fully qualified name of the object. The fully qualified name prepends the topmost parent, followed by each sub element separated by colons. Takes the form of Customer:Job:Sub-job. System generated. Limited to 5 levels.

// Level
// read only
// system defined
// Integer
// Specifies the level of the hierarchy in which the entity is located. Zero specifies the top level of the hierarchy; anything above will be level with respect to the parent. Constraints:up to 5 levels
// TaxExemptionReasonId
// minorVersion: 10
// Numeric Id
// The tax exemption reason associated with this customer object. Applicable if automated sales tax is enabled (Preferences.TaxPrefs.PartnerTaxEnabled is set to true) for the company. Set TaxExemptionReasonId: to one of the following:
// ID	REASON
// 1	Federal government
// 2	State government
// 3	Local government
// 4	Tribal government
// 5	Charitable organization
// 6	Religious organization
// 7	Educational organization
// 8	Hospital
// 9	Resale
// 10	Direct pay permit
// 11	Multiple points of use
// 12	Direct mail
// 13	Agricultural production
// 14	Industrial production / manufacturing
// 15	Foreign diplomat


// {
//     "Employee": {
//       "SyncToken": "0",
//       "domain": "QBO",
//       "DisplayName": "Bill Miller",
//       "PrimaryPhone": {
//         "FreeFormNumber": "234-525-1234"
//       },
//       "PrintOnCheckName": "Bill Miller",
//       "FamilyName": "Miller",
//       "Active": true,
//       "SSN": "XXX-XX-XXXX",
//       "PrimaryAddr": {
//         "CountrySubDivisionCode": "CA",
//         "City": "Middlefield",
//         "PostalCode": "93242",
//         "Id": "116",
//         "Line1": "45 N. Elm Street"
//       },
//       "sparse": false,
//       "BillableTime": false,
//       "GivenName": "Bill",
//       "Id": "71",
//       "MetaData": {
//         "CreateTime": "2015-07-24T09:34:35-07:00",
//         "LastUpdatedTime": "2015-07-24T09:34:35-07:00"
//       }
//     },
//     "time": "2015-07-24T09:35:54.805-07:00"
//   }

// Id
// * Required for update
// read only
// system defined
// String , filterable , sortable
// Unique identifier for this object. Sort order is ASC by default.

// SyncToken
// * Required for update
// read only
// system defined
// String
// Version number of the object. It is used to lock an object for use by one app at a time. As soon as an application modifies an object, its SyncToken is incremented. Attempts to modify an object specifying an older SyncToken fails. Only the latest version of the object is maintained by QuickBooks Online.

// PrimaryAddr
// * Conditionally required
// max character: maximum 30 characters
// PhysicalAddress
// Represents the physical street address for this employee. If QuickBooks Payroll is enabled for the company, the following PhysicalAddress fields are required:
// City, maximum of 255 chars
// CountrySubDivisionCode, maximum of 255 chars

// PostalCode
// Required when QuickBooks Payroll is enabled.
// If a physical address is updated from within the transaction object, the QuickBooks Online API flows individual address components differently into the Line elements of the transaction response then when the transaction was first created:
// Line1 and Line2 elements are populated with the customer name and company name.
// Original Line1 through Line 5 contents, City, SubDivisionCode, and PostalCode flow into Line3 through Line5as a free format strings.
// Show more details
// Show child attributes

// PrimaryEmailAddr
// Optional
// EmailAddress
// Primary email address.
// Show child attributes

// DisplayName
// Optional
// max character: maximum of 500 chars
// String , filterable , sortable
// The name of the person or organization as displayed. Default Value: If not supplied, the system generates DisplayName by concatenating employee name components supplied in the request from the following list: Title, GivenName, MiddleName, FamilyName, and Suffix. When QuickBooks Payroll is enabled, this attribute is read-only and a concatenation of GivenName, MiddleName, and FamilyName.

// Title
// Optional
// max character: maximum 16 chars
// String
// Title of the person. This tag supports i18n, all locale. Not supported when QuickBooks Payroll is enabled.

// BillableTime
// Optional
// Boolean
// If true, this entity is currently enabled for use by QuickBooks.

// GivenName
// Optional
// max character: maximum of 100 chars
// String , filterable , sortable
// Given name or family name of a person. At least one of GivenName or FamilyName attributes is required.

// BirthDate
// Optional
// Date
// Birth date of the employee.
// Show child attributes

// MiddleName
// Optional
// max character: maximum of 100 chars
// String , filterable , sortable
// Middle name of the person. The person can have zero or more middle names.

// SSN
// Optional
// max character: max 100 chars
// String
// Social security number (SSN) of the employee. If SSN is set, it is masked in the response with XXX-XX-XXXX. If XXX-XX-XXXX is sent in the create or update request, XXX-XX-XXXX is ignored and the old value is preserved. This attribute cannot be passed in a request when QuickBooks Payroll is enabled. Code for this field must be removed before submitting.

// PrimaryPhone
// Optional
// max character: maximum of 20 chars
// TelephoneNumber
// Primary phone number.
// Show child attributes

// Active
// Optional
// Boolean , filterable
// If true, this entity is currently enabled for use by QuickBooks.

// ReleasedDate
// Optional
// Date
// Release date of the employee.
// Show child attributes

// MetaData
// Optional
// ModificationMetaData
// Descriptive information about the object. The MetaData values are set by Data Services and are read only for all applications.

// Mobile
// Optional
// max character: maximum of 20 chars
// TelephoneNumber
// Mobile phone number.
// Show child attributes

// Gender
// Optional
// String
// Gender of the employee. To clear the gender value, set to Null in a full update request. Supported values include: Male or Female.

// HiredDate
// Optional
// Date
// Hire date of the employee.
// Show child attributes

// BillRate
// Optional
// BigDecimal
// This attribute can only be set if BillableTime is true. Not supported when QuickBooks Payroll is enabled.

// Organization
// Optional
// Boolean
// true--the object represents an organization. false--the object represents a person.

// Suffix
// Optional
// max character: maximum of 16 chars
// String , filterable , sortable
// Suffix of the name. For example, Jr. Not supported when QuickBooks Payroll is enabled.

// FamilyName
// Optional
// max character: maximum of 100 chars
// String , filterable , sortable
// Family name or the last name of the person. At least one of GivenName or FamilyName attributes is required.

// PrintOnCheckName
// Optional
// max character: maximum of 100 chars
// String , filterable , sortable
// Name of the person or organization as printed on a check. If not provided, this is populated from DisplayName. Cannot be removed with sparse update. Not supported when QuickBooks Payroll is enabled.

// EmployeeNumber
// Optional
// max character: max 100 chars
// String
// Specifies the ID number of the employee in the employer's directory.
// V4IDPseudonym
// read only
// minorVersion: 26
// String
