import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { GlobalmemService } from '../Services/globalmem.service';
import { AuthorizationService } from '../services/authorization.service';
// import { ifStmt } from '@angular/compiler/src/output/output_ast';
interface RetVal {
  rslt: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  uid = '';
  userEmail = '';
  rights = '';

  constructor(
    public afAuth: AngularFireAuth,
    private authorize: AuthorizationService,
    private http: HttpClient
  ) { }


  urlGrant = 'https://us-central1-flex-c63d9.cloudfunctions.net/grantA';
  urlGet = 'https://us-central1-flex-c63d9.cloudfunctions.net/getA';
  urlSendMail = 'https://us-central1-flex-c63d9.cloudfunctions.net/sendMyMail';
  urlSendSMS = 'https://us-central1-flex-c63d9.cloudfunctions.net/sendSMS';

  // async InitialUser() {
  //   const rslt = await this.setCustom('jeffc@infomis.com','9MASTER   SSSSSSSSSSSSSSSSSSSSSSS');
  // }

  //#region Get Set Customs Claims Rights
  async setCustom(email: string, astr: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.urlGrant;

    const body = { uid: this.uid, targetEmail: email, aString: astr };
    try {
      const postResult: any = await this.http.post(url, body, httpOptions).toPromise();
      console.log('POST call successful value returned in body', postResult);
      return postResult.rslt;
    } catch (err) {
      console.log('POST call in error', err);
      return 'Error';
    }
  }

  // get the custom claim for the email address
  async getCustom(email: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.urlGet;
    const body = { uid: this.uid, targetEmail: email };
    try {
      const postResult: any = await this.http.post(url, body, httpOptions).toPromise();
      console.log('POST call successful value returned in body', postResult);
      return postResult.rslt;
    } catch (err) {
      console.log('POST call in error', err);
      if (err.error.rslt === "Didn't get targetEmail") {
        return 'Not Found';
      }
      return 'Error';
    }
  }

  //#endregion Get Set Rights

  //#region Mail and SMS
  async sendTextMsg() {
    this.sendSMS('+19544248915', 'Test Message This is a test message');
  }

  async sendSMS(smsTo: string = '', message: string = '') {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.urlSendSMS;
    //
    const ebody = { "to": smsTo, "body": message };
    try {
      const postResult: any = await this.http.post(url, ebody, httpOptions).toPromise();
      console.log('POST call successful value returned in body', postResult);
      return postResult.rslt;
    } catch (err) {
      console.log('POST call in error', err);
      return err;
    }
  }


  async sendEMail() {
    this.sendMail("jeffjb@infomis.com", "Test Message", "This is a test message");
  }


  async sendMail(email: string = '', subject: string = '', message: string = '') {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.urlSendMail;
    //
    const body = { "MailBody": message, "Subject": subject, "eTarget": email };
    try {
      const postResult: any = await this.http.post(url, body, httpOptions).toPromise();
      console.log('POST call successful value returned in body', postResult);
      return postResult.rslt;
    } catch (err) {
      console.log('POST call in error', err);
      return err;
    }
  }
  //#endregion

  // password reset
  async resetPassword(email: string) {
    return await this.afAuth.auth.sendPasswordResetEmail(email);
  }

  // getLoggedInUser
  async refreshRights() {
    const uid = await this.getLoggedInUser();
    await this.checkAuthority();
  }

  async getLoggedInUser() {
    if (this.afAuth.auth.currentUser) {
      this.uid = await this.afAuth.auth.currentUser.getIdToken(true);
      return this.afAuth.auth.currentUser;
    }
    return null;
  }

  // create an account and basic security string
  async createAccount(email: string, password: string, company: string, userId: string, secureString: string) {
    if(email.length == 0) {
      return('Missing eMail');
    }
    if(password.length == 0) {
      return('Password too short');
    }
    if(company.length == 0) {
      return('Missing company');
    }
    if(userId.length == 0) {
      return('Missing userId');
    }
    if(secureString.length == 0) {
      return('Missing securityString');
    }
    const sstring = this.createSecurityString(company,userId,secureString);
    try {
      const user = await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      // at the same time we must create basic security
      const rslt = await this.setCustom(email,sstring);
      return user;
    } catch (err) {
      return err;
    }
  }

  createSecurityString(company: string, userId: string, secureString: string) {
    return secureString = '9' + company.trim().padEnd(10) + userId.trim().padEnd(10)+ secureString;
  }

  // Login returns 0 for success, 1 for bad password 2 bad user otherwise 3
  async login(email: string, password: string) {
    try {
      const user = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      this.userEmail = user.user.email;
      this.updateSec();
      console.log(user);
      return 0;
    } catch (err) {
      this.authorize.userRights.next(''); // cancel all current rights on ad login
      console.log(err);
      if (err.code === 'auth/wrong-password') {
        return 1;
      } else if (err.code === 'auth/user-not-found') {
        return 2;
      } else {
        return 3;
      }
    }
  }

  logonAnonymously() {
    this.afAuth.auth.signInAnonymously();
  }

  async updateSec() {
    const cu = this.afAuth.auth.currentUser;
    if (cu) {
      const idToken = await cu.getIdTokenResult();
      this.authorize.userRights.next(idToken.claims.ga);
    } else {
      this.authorize.userRights.next('');
    }
  }

  async getCurrentCompany() { // company ID from 1 to 10
    const cu = this.afAuth.auth.currentUser;
    if (cu) {
      const idToken = await cu.getIdTokenResult();
      const clstring: string = idToken.claims.ga;
      if (clstring.length >= 11) {
          return clstring.substr(1, 10).trim();
      }
    }
    return '';  // just return empty aka fail
  }

  async getCurrentUserId() { // company ID from 1 to 12
    const cu = this.afAuth.auth.currentUser;
    if (cu) {
      const idToken = await cu.getIdTokenResult();
      const clstring: string = idToken.claims.ga;
      if (clstring.length >= 21) {
        return clstring.substr(11, 10).trim();
      }
    }
    return '';  // just return empty aka fail
  }

  async userExists(eMail: string) {
    try {
      const signMethod = await this.afAuth.auth.fetchSignInMethodsForEmail(eMail);
      return true;
    } catch {
      return false;
    }
  }

  async logout() {
    await this.afAuth.auth.signOut();
    this.authorize.userRights.next('');
  }

  // call to update security spot check before authorizing
  async checkAuthority() {
    this.updateSec();
  }

}

