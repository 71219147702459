export class Messages  {
  MessagesID                :string;
  FromID                   :string;
  Compcode                 :string;
  CreateTime               :string;
  Orig_ID                  :string;
  Orig_Name                :string;
  Dest_ID                  :string;
  Dest_Name                :string;
  Message                  :string;
  Sent                     :boolean;
  Delivered                :boolean;
  Read                     :boolean;
  selected                 :boolean;
  visible                  :boolean;
  active                   :boolean;

constructor() {
  this.MessagesID = '';
  this.FromID = '';
  this.Compcode = '';
  this.CreateTime = '';
  this.Orig_ID = '';
  this.Orig_Name = '';
  this.Dest_ID = '';
  this.Dest_Name = '';
  this.Message = '';
  this.Sent = false;
  this.Delivered = false;
  this.Read = false;
  this.selected = false;
  this.visible = false;
  this.active = false;
  }

  set(init?: Partial<Messages>) {
      Object.assign(this, init);
  }

  toPlainObj() {
      return Object.assign({}, this);
  }
}

