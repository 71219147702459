import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AuthenticationService } from '../services/authentication.service';
import { FireDataService } from '../services/fire-data.service';



@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

  constructor(
    public storage: Storage,
    private auth: AuthenticationService,
    private firedata: FireDataService
  ) { }

  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  async login(username: string, password: string): Promise<any> {
    const result = await this.auth.login(username,password)
    if(result == 0) {
      // const rslt = await this.storage.set(this.HAS_LOGGED_IN, true);
      this.firedata.setCompany(await (await this.auth.getCurrentCompany()).trim());
      this.firedata.setUserId(await (await this.auth.getCurrentUserId()).trim());
      this.firedata.setUserName(username);
      this.setUsername(username);
      this.firedata.configUpdater(); // get the company config on successful login
      return window.dispatchEvent(new CustomEvent('user:login'));
    } else { // result 1 2 3 bad username or password
      return false;
    }
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:signup'));
    });
  }

  logout(): Promise<any> {
    this.auth.logout();
    this.firedata.clearCompany();
    this.firedata.clearUserId();
    this.firedata.clearUserName();
    return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
      return this.storage.remove('username');
    }).then(() => {
      window.dispatchEvent(new CustomEvent('user:logout'));
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }
}
