import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Name } from '../models/name';
import { Namelist } from '../models/namelist';
import { Job } from '../models/job';
import { Subject } from 'rxjs';
import { Notes } from '../models/notes';
import { Messages } from '../models/messages';
import { Config } from '../models/config';

import { analytics } from 'firebase';
import * as firebase from '../../../flex/node_modules/firebase';

export interface entity {
  type: string;
  id: string;
  email: string;
}


@Injectable({

  providedIn: 'root'
})

export class FireDataService {
  noteslist : Notes[] = []
  notesNotify = new Subject<boolean>();
  Messages: Notes[];
  nameNotify = new Subject<Namelist>();
  nameLists: Namelist[];
  company = '';
  userId = '';
  userName = '';
  CompanyConfig = new Config();

  joblist : Job[] = [];
  subjoblist : Job[] = [];
  jobNotify = new Subject<Job[]>();
  subjobNotify = new Subject<Job[]>();

  entityIdChange: Subject<entity> = new Subject<entity>(); // used to track

  messagesNotify = new Subject<boolean>();
  messageslist : Messages[];

  notePointers = {}

  configlist : Config[] = []
  // Initializers
  // Notifier when shapshot changes are updated
 	configNotify = new Subject<boolean>();




  constructor(
    private db: AngularFirestore
    ) { this.nameLists = []; }

  setCompany(comp: string) {
    this.company = comp;
    // get the current employee data
    // get all of the names?
  }
  clearCompany() {
    this.company = '';
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  clearUserId()  {
    this.userId = '';
  }

  setUserName(userName:string) {
    this.userName = userName;
  }
  clearUserName() {
    this.userName = '';
  }



  // #region notes

  async notesGet(from:string,target:Notes[]){
    this.notePointers[from] = target;
    if(this.company.length > 0){
      try {
        const rslt = await this.db.collection('companies').doc(this.company)
        .collection('Notes',ref=>ref.where('FromID','==',from)).get().toPromise();
        this.addNotesDataToPassedArray(rslt, target);
        return true;
      }
      catch (err) {
        console.log(err);
        return false;
      }
    } else {
      console.log('Error - No company set');
      return false;
    }
  }

  private addNotesDataToPassedArray(svrPoints, arr) {
    svrPoints.docs.forEach( ojt => {
      const item: any = ojt.data();
      const qp = new Notes();
      qp.set(item);
      qp.notesId = ojt.id;
      qp.selected = false;
      arr.push(qp);  // add to local array
    });
    arr.sort((a,b)=>(a.CreateTime > b.CreateTime)? -1 : 1); //sort by date in descending order
  }


 // SNAPSHOT CHANGES
 async userAlertUpdater(to:string){
  if(this.company.length > 0){
    const fc = this.db.collection('companies').doc(this.company)
    .collection('Notes',ref=>ref.where('Alert','==',to)).snapshotChanges().subscribe((rslt)=>{
      const messageCount = this.Messages.length;
      this.addNotesToMessageArray(rslt);
      const newMessageCount = this.Messages.length;
    });
  } else {
    console.log('Error - No company set');
  }
}

private addNotesToMessageArray(svrPoints) {
  let firstTime = true;
  let aName = '';
  svrPoints.forEach( ojt => {
    const item: any = ojt.payload.doc.data();
    const qp = new Notes();
    qp.set(item);
    qp.selected = false;
    this.Messages.push(qp);  // add to local array
  });
  this.Messages.sort((a,b)=>(a.CreateTime > b.CreateTime)? -1 : 1); //sort by date in descending order
}


 async notesUpdater(from:string,target:Notes[]){
    this.notePointers[from] = target;
    if(this.company.length > 0){
      const fc = this.db.collection('companies').doc(this.company)
      .collection('Notes',ref=>ref.where('FromID','==',from)).snapshotChanges().subscribe((rslt)=>{

        this.addNotesToPassedArray(rslt);
       //  see if we can get the
        this.notesNotify.next(true);
      });
    } else {
      console.log('Error - No company set');
    }
  }

  private addNotesToPassedArray(svrPoints) {
    let firstTime = true;
    let aName = '';
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Notes();
      qp.set(item);
      qp.notesId = ojt.payload.doc.id;
      let theArray = this.notePointers[qp.FromID];
      if (firstTime) {
        aName = qp.FromID;
        this.notePointers[qp.FromID].length = 0;
        firstTime = false;
      }
      qp.selected = false;
      theArray.push(qp);  // add to local array
    });
    this.notePointers[aName].sort((a,b)=>(a.CreateTime > b.CreateTime)? -1 : 1); //sort by date in descending order
  }

  private addNotesToLocalArray(svrPoints) {
    this.noteslist = [];
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Notes();
      qp.set(item);
      qp.notesId = ojt.payload.doc.id;
      this.noteslist.push(qp);  // add to local array
    });
    this.noteslist.sort((a,b)=>(a.CreateTime > b.CreateTime)? -1 : 1);
  }

    saveNotes(notes: Notes, DocID: string = '', targetId:string = ''){  // does either an add or update based on if we have a nameID
      if(this.company.length > 0){
        if(notes.notesId.length == 0) {
          notes.CreateTime = new Date().toISOString();
          notes.LastUpdateTime = notes.CreateTime;
          notes.FromID = DocID;
          notes.Enteredby = this.userId;
          this.db.collection('companies').doc(this.company).collection('Notes')
          .add(notes.toPlainObj());
        } else {
          notes.ChangedBy = this.userId;
          notes.LastUpdateTime = new Date().toISOString();
          if(targetId.length > 0 ) { // used for setting headers
            this.db.collection('companies').doc(this.company)
            .collection('Notes').doc(targetId).set(notes.toPlainObj());
          } else {
            this.db.collection('companies').doc(this.company)
            .collection('Notes').doc(notes.notesId).update(notes.toPlainObj());
          }
        }
      } else {
        console.log('Error - No company set');
      }
    }

    async getNotes( name: string, heading:Notes) {
      if(this.company.length > 0) {
        try {
          const data = await this.db.collection('companies').doc(this.company)
          .collection('Notes').doc(name).get().toPromise();
          const jsonObj = data.data
        // heading.set(jsonObj);
          return true;
        } catch {
          return false;
        }
      }
      return false;
    }


    deleteNotes(notes: Notes){
      if(this.company.length  > 0){
        this.db.collection('companies').doc(this.company)
      .collection('Notes').doc(notes.notesId).delete();
      } else {
        console.log('Error - No company set');
      }
    }
//  #endregion notes

 // #region messages

 // SNAPSHOT CHANGES
 async messagesUpdater(){
  if(this.company.length > 0){
    const fc = this.db.collection('companies').doc(this.company)
    .collection('Messages').snapshotChanges().subscribe((rslt)=>{
      this.addMessagesToLocalArray(rslt);
      // see if we can get the
      this.messagesNotify.next(true);
    });
  } else {
    console.log('Error - No company set');
  }
}

  private addMessagesToLocalArray(svrPoints) {
    this.messageslist = [];
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Messages();
      qp.set(item);
      qp.MessagesID = ojt.payload.doc.id;
      this.messageslist.push(qp); // add to local array
    });
    // this.messageslist.sort(); // ******* Create A Sort Function ******
  }

  saveMessages(messages: Messages, DocID: string = ''){  // does either an add or update based on if we have a nameID
    if(this.company.length > 0){
      if(messages.MessagesID.length == 0) {
        messages.CreateTime = new Date().toISOString();
        this.db.collection('companies').doc(this.company).collection('Messages')
        .add(messages.toPlainObj());
      } else {
        if(DocID.length > 0 ) { // used for setting headers
          this.db.collection('companies').doc(this.company)
          .collection('Messages').doc(DocID).set(messages.toPlainObj());
        } else {
          this.db.collection('companies').doc(this.company)
          .collection('Messages').doc(messages.MessagesID).update(messages.toPlainObj());
        }
      }
    } else {
      console.log('Error - No company set');
    }
  }
  async getMessages( name: string, heading:Messages) {
    if(this.company.length > 0){
      const data = await this.db.collection('companies').doc(this.company)
      .collection('Messages').doc(name).get().toPromise();
    }
  }

  deleteMessages(messages: Messages){
    if(this.company.length  > 0){
      this.db.collection('companies').doc(this.company)
    .collection('Messages').doc(messages.MessagesID).delete();
    } else {
      console.log('Error - No company set');
    }
  }
// #endregion messages

 // #region Names

getNameList(nameType: string) {
  this.nameLists.forEach( namelist => {
    if(namelist.lookup === nameType) {
      return namelist;
    }
  });
  const nl = new Namelist(nameType,'');
  this.nameLists.push(nl);
  return nl;
}

async setUpdater(type: string){
  // see if we have a namelist class if not create one
  if(this.company.length > 0){
    const nl = this.getNameList(type);
    if(nl.sub){
      nl.sub.unsubscribe;
    }
    const fc = this.db.collection('companies').doc(this.company)
    .collection(type).snapshotChanges().subscribe((rslt)=>{
      this.addNameToLocalArray(rslt, nl);
      // see if we can get the
      this.nameNotify.next(nl);
    });
  } else {
    console.log('Error - No company set')
  }
}

  private addNameToLocalArray(svrPoints, namelist: Namelist) {
    let currType = '';
    namelist.data = [];
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Name();
      qp.set(item);
      qp.nameId = ojt.payload.doc.id;
      qp.selected = false;
      namelist.data.push(qp); // add to local array based on TYPE ie 00000 00001 etc
      currType = qp.Type;
    });
    namelist.sort();
  }

  saveName(nm: Name, DocID: string = ''){ // does either an add or update based on if we have a nameID
    if(this.company.length > 0){
      if(nm.nameId.length == 0) {
        nm.CreateTime = new Date().toISOString();
          this.db.collection('companies').doc(this.company)
          .collection(nm.Type).add(nm.toPlainObj());
      } else {
        nm.LastUpdatedTime = nm.CreateTime;
        if(DocID.length > 0 ) { // used for setting headers
          this.db.collection('companies').doc(this.company)
          .collection(nm.Type).doc(DocID).set(nm.toPlainObj());
        } else {
        nm.LastUpdatedTime = new Date().toISOString();
        this.db.collection('companies').doc(this.company)
        .collection(nm.Type).doc(nm.nameId).update(nm.toPlainObj());
        }
      }
    } else {
      console.log('Error - No company set')
    }
  }

  async getName(type: string, name: string, heading: Name) {
    if(this.company.length > 0){
      if(type.length > 0) {
        const data = await this.db.collection('companies').doc(this.company)
          .collection(type).doc(name).get().toPromise();
        }

      }
  }

  deleteName(nm: Name){
    if(this.company.length > 0){
      this.db.collection('companies').doc(this.company)
    .collection(nm.Type).doc(nm.nameId).delete();
    } else {
      console.log('Error - No company set')
    }
  }
//#endregion Names


 // #region job

 // SNAPSHOT CHANGES
 async jobUpdater(){
  if(this.company.length > 0){
    const fc = this.db.collection('companies').doc(this.company)
    .collection('Job').snapshotChanges().subscribe((rslt)=>{
      this.addJobToLocalArray(rslt);
      // see if we can get the
      this.jobNotify.next(this.joblist);
    });
  } else {
    console.log('Error - No company set');
  }
}


async jobFilter(filtType: string) {
  if(this.company.length > 0){
    let fc;
    switch(filtType ){
      case 'Dispatching':
        { fc = this.db.collection('companies').doc(this.company)
        .collection('Job',ref => ref.where('Status', 'in', ['NEEDS CARRIER','BOOKED'])).snapshotChanges().subscribe((rslt)=>{
          this.addJobToLocalArray(rslt);
        this.jobNotify.next(this.joblist);
        });
        break;}
      case 'Tracking':
        { fc = this.db.collection('companies').doc(this.company)
          .collection('Job',ref => ref.where('Status', 'in', ['DRIVER IN','EN ROUTE','UNLOADING'])).snapshotChanges().subscribe((rslt)=>{
            this.addJobToLocalArray(rslt);
          this.jobNotify.next(this.joblist);
          });
          break;
          }
      default: {
          const fc = this.db.collection('companies').doc(this.company)
          .collection('Job').snapshotChanges().subscribe((rslt)=>{
            this.addJobToLocalArray(rslt);
            // see if we can get the
            this.jobNotify.next(this.joblist);
          });
        }
    }
  } else {
    console.log('Error - No company set');
  }
}

  private addJobToLocalArray(svrPoints) {
    this.joblist = [];
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Job();
      qp.set(item);
      qp.JobID = ojt.payload.doc.id;
      this.joblist.push(qp); // add to local array
    });
    // this.joblist.sort(); // ******* Create A Sort Function ******
  }

async saveJob(job: Job, DocID: string = ''){  // does either an add or update based on if we have a nameID
    if(this.company.length > 0){
      if(job.JobID.length == 0) { // new job
        job.CreateTime = new Date().toISOString();
        job.LastUpdateTime = job.CreateTime;
        const rslt = await this.db.collection('companies').doc(this.company).collection('Job').add(job.toPlainObj());
        // return the ID
        return rslt;
      } else {
        job.LastUpdateTime = job.CreateTime;
        if(DocID.length > 0 ) { // used for setting headers
          this.db.collection('companies').doc(this.company)
          .collection('Job').doc(DocID).set(job.toPlainObj());
        } else {
          job.LastUpdateTime = new Date().toISOString();
          this.db.collection('companies').doc(this.company)
          .collection('Job').doc(job.JobID).update(job.toPlainObj());
        }
      }
    } else {
      console.log('Error - No company set');
    }
  }

  async getJob( name: string, heading:Job) {
    if(this.company.length > 0){
      const data = await this.db.collection('companies').doc(this.company)
      .collection('Job').doc(name).get().toPromise();
    }
  }

  async getSubJobs(subof:string){
    const fc = this.db.collection('companies').doc(this.company)
      .collection('Job',ref => ref.where('FromID', '==', subof)).snapshotChanges().subscribe((rslt)=>{
      this.addSubJobToLocalArray(rslt);
      if(this.subjoblist.length > 1){
       this.subJobArraySort();
      }
      this.subjobNotify.next(this.subjoblist);
    });
  }
  subJobArraySort() {
    this.subjoblist.sort((a,b)=>(a.Est_StartBegin > b.Est_StartBegin)? 1 : -1); //sort by date in ascending order
  }

  private addSubJobToLocalArray(svrPoints) {
    this.subjoblist = [];
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Job();
      qp.set(item);
      qp.JobID = ojt.payload.doc.id;
      this.subjoblist.push(qp); // add to local array
    });
    // this.joblist.sort(); // ******* Create A Sort Function ******
  }



  async lookupJob(type:number, refno: any) {
    if(this.company.length > 0){
      let data: any;
      switch(type) {
        case 0: // reference
          data = await this.db.collection('companies').doc(this.company).collection('Job',ref => ref.where('Reference', '==', +refno)).get().toPromise();
          break;
        case 1: // alt reference
          data = await this.db.collection('companies').doc(this.company).collection('Job',ref => ref.where('AltReference', '==', refno)).get().toPromise();
          break;
        case 2: // booking
          data = await this.db.collection('companies').doc(this.company).collection('Job',ref => ref.where('Booking', '==', refno)).get().toPromise();
          break;
        default:
          return undefined;
      }
      if(!data.empty){
        const qpa = new Job();
        qpa.set(data.docs[0].data());
        return(qpa);
      } else {
        return undefined;
      }
    }
  }

  deleteJob(job: Job){
    if(this.company.length  > 0){
      this.db.collection('companies').doc(this.company)
    .collection('Job').doc(job.JobID).delete();
    } else {
      console.log('Error - No company set');
    }
  }
// #endregion job

// #region config
async getCompanyConfig(configNo = 1){
    if(this.company.length > 0){
      try {
        const rslt = await this.db.collection('companies').doc(this.company)
        .collection('Config',ref=>ref.where('configNumber','==',configNo)).get().toPromise();
        if(rslt.empty){ // if no config create one for this company
        this.CompanyConfig.configNumber = configNo;
          this.db.collection('companies').doc(this.company).collection('Config').add(this.CompanyConfig.toPlainObj());
          console.log('empty config');
        } else {
          rslt.docs.forEach( ojt => { // should only be one object for a unique config number
            const item: any = ojt.data();
            let cf = new Config();
            cf.set(item);
            this.configlist.push(cf);
        });
        console.log(rslt);
        return rslt;
        }
      } catch (err) {
        console.log(err);
        // if the configuration doesn't exist add a blank one
        // if(err) {
        // }
      }
    } else {
      // not logged in
      console.log('Error - No company set');
      return false;
    }
 }


 // SNAPSHOT CHANGES
 configUpdater(){
  if(this.company.length > 0){
    const fc = this.db.collection('companies').doc(this.company)
    .collection('Config').snapshotChanges().subscribe((rslt)=>{
      this.addConfigToLocalArray(rslt);
      // see if we can get the
      this.configNotify.next(true);
    });
  } else {
    console.log('Error - No company set');
  }
}
  private addConfigToLocalArray(svrPoints) {
    this.configlist = [];
    svrPoints.forEach( ojt => {
      const item: any = ojt.payload.doc.data();
      const qp = new Config();
      qp.set(item);
      qp.configID = ojt.payload.doc.id;
      this.configlist.push(qp); // add to local array
    });
    // this.configlist.sort(); // ******* Create A Sort Function ******
  }

  async incrementTemp(configno = 0) {
    let cval = 0;
    const configid = this.configlist[configno].configID;
    const updoc = this.db.collection('companies').doc(this.company).collection('Config').doc(configid);
    await updoc.ref.get().then((choice) => {
      cval = choice.data().lastTempNum;
      updoc.update({
        lastTempNum: --cval
      });
    });
    return cval;
  }


  async incrementReal(configno = 0) {
    let cval = 0;
    const configid = this.configlist[configno].configID;
    const updoc = this.db.collection('companies').doc(this.company).collection('Config').doc(configid);
    await updoc.ref.get().then((choice) => {
      cval = choice.data().lastRealNum;
      updoc.update({
        lastRealNum: ++cval
      });
    });
    return cval;
  }


  saveConfig(config: Config, DocID: string = ''){  // does either an add or update based on if we have a nameID
    if(this.company.length > 0){
      if(config.configID.length == 0) {
        config.CreateTime = new Date().toISOString();
        config.LastUpdateTime = config.CreateTime;
        this.db.collection('companies').doc(this.company).collection('Config')
        .add(config.toPlainObj());
      } else {
        config.LastUpdateTime = config.CreateTime;
        if(DocID.length > 0 ) { // used for setting headers
          this.db.collection('companies').doc(this.company)
          .collection('Config').doc(DocID).set(config.toPlainObj());
        } else {
          config.LastUpdateTime = new Date().toISOString();
          this.db.collection('companies').doc(this.company)
          .collection('Config').doc(config.configID).update(config.toPlainObj());
        }
      }
    } else {
      console.log('Error - No company set');
    }
  }
  async getConfig( name: string, heading:Config) {
    if(this.company.length > 0){
      const data = await this.db.collection('companies').doc(this.company)
      .collection('Config').doc(name).get().toPromise();
    }
  }

  deleteConfig(config: Config){
    if(this.company.length  > 0){
      this.db.collection('companies').doc(this.company)
    .collection('Config').doc(config.configID).delete();
    } else {
      console.log('Error - No company set');
    }
  }
// #endregion config


} // end of class
